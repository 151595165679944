<template>
  <div class="notebookItem">
    <div class="sub-title">个性化错题本</div>
    <div class="fitler-box">
      <div class="filter-left">
        <div class="fitler-item">
          学段：
          <el-select
            v-model="searchItems.level"
            placeholder="全部学段"
            @change="changeLevel()"
          >
            <el-option
              v-for="item in levelOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="fitler-item">
          入学年份：
          <el-select
            v-model="searchItems.year"
            placeholder="入学年份"
            @change="changeGrade()"
          >
            <el-option
              v-for="item in yearList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="fitler-item">
          学科：
          <el-select
            v-model="searchItems.subjectId"
            :collapse-tags="true"
            placeholder="全部学科"
            @change="handleClick()"
          >
            <el-option
              v-for="item in examsubjectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="fitler-item">
          班级：
          <el-select
            v-model="searchItems.classNum"
            :collapse-tags="true"
            placeholder="全部班级"
            @change="handleClick()"
          >
            <el-option
              v-for="item in classNumList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <el-button type="primary" @click="createSetting()">创建</el-button>
    </div>
    <no-data message="暂无内容！"></no-data>
  </div>
</template>
<script>
import { getSubjectList, getYear } from "@/core/util/util";
import { levelOptions } from "@/core/util/constdata";
export default {
  name: "NotebookItem",
  data() {
    return {
      examsubjectOptions: getSubjectList(),
      yearList: getYear(),
      levelOptions: levelOptions(),
      classNumList: [],
      searchItems: {
        subjectId: "",
        year: "",
        level: "",
        classNum: "",
      },
    };
  },
  created() {},
  methods: {
    changeLevel() {
      this.subjectOptions = getSubjectList(this.searchItems.level);
    },
    createSetting() {
      this.$router.push({
        path: "/newAnaly/errorQuestionSetting",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.notebookItem {
  background-color: #ffffff;
  width: 1200px;
  margin: 24px auto;
  padding: 18px;
  .fitler-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .fitler-item {
      margin-right: 12px;
    }
    .el-select {
      width: 120px;
    }
    .filter-left {
      display: flex;
      align-items: center;
    }
  }
  .sub-title {
    margin-bottom: 18px;
    font-size: 16px;
    color: #0a1119;
  }
}
</style>
